<template>
  <v-container>
    <v-card class="pa-4" shaped>
      <v-card-title>
        <v-row>
          <div class="flex font-weight-bold">Payments</div>
          <v-text-field
            class="shrink mr-2 mb-4"
            v-model="search"
            outlined
            rounded
            dense
            append-icon="mdi-magnify"
            @click:append="searchInput"
            clearable
            label="Find"
            single-line
            hide-details
          ></v-text-field>
          <v-select
            class="shrink mb-0"
            v-model="filterType"
            validate-on-blur
            :items="filterTypes"
            label="Filter"
            @change="filterSelected"
            outlined
            rounded
            dense
          ></v-select>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="header"
          :search="search"
          :items="transactions"
          :server-items-length="count"
          :loading="loading"
          class="elevation-0"
          v-on:update:options="getOptions"
        >
          <template v-slot:item.amount="{ item }">
            <v-chip
              color="light-green darken-3"
              pill
              class="white--text font-weight-bold"
            >
              {{ formatCurrencyDecimal(item.amount, 2, true) }}
            </v-chip>
          </template>
          <template v-slot:item.balance="{ item }">
            <v-chip
              color="red darken-3"
              pill
              class="white--text font-weight-bold"
            >
              {{ formatCurrencyDecimal(item.balance, 2, true) }}
            </v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <v-chip
              color="teal darken-3"
              pill
              class="white--text font-weight-bold"
            >
              {{ item.createdAt }}
            </v-chip>
          </template>

          <!-- <template v-slot:item.action="{ item }">
            <v-icon class="mr-16" color="red" @click="openWarn(item)"
              >mdi-delete</v-icon
            >
            <v-icon class="mr-0" color="primary" @click="showDetail(item)"
              >mdi-open-in-new</v-icon
            >
          </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="520">
      <PayEdit ref="dialog" v-on="handlers" />
    </v-dialog>
    <v-dialog v-model="warnDlg" max-width="280">
      <v-card class="pa-6">
        <v-card-text>
          <v-row><div class="title">Remove Payment?</div></v-row>
          <v-row
            ><div class="caption mt-2 mb-4">
              This cannot be reversed!
            </div></v-row
          >
          <v-row class="justify-space-between">
            <v-btn @click="warnDlg = false" outlined color>Cancel</v-btn>
            <v-btn dark :loading="loading" color="red" @click="remove"
              >Remove</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PayEdit from "./payEdit.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { PayEdit },
  data() {
    return {
      handlers: {
        "close-dialog": this.closeDiag,
        refresh: this.refresh,
      },
      selectedItem: {},
      warnDlg: false,
      last: ["none"],
      lastPage: 0,
      dialog: false,
      loading: false,
      options: null,
      search: null,
      dates: ["2019-09-10", "2019-09-20"],
      menu: false,
      dateMenu: false,
      datesForDisplay: null,
      filterTypes: ["All", "Paystack", "Flutterwave", "Remita"],
      filterType: "All",
      // options: null,
      // search: null,
      startDate: null,
      stopDate: null,
      // dialog: false,
      selected: { display: "This Month", id: "month" },
      menuItems: [
        { display: "Today", id: "today" },
        { display: "This Week", id: "week" },
        { display: "This Month", id: "month" },
        { display: "This Quarter", id: "quarter" },
        { display: "This Year", id: "year" },
        { display: "Range Select", id: "range" },
        { display: "All", id: "all" },
      ],
      header: [
        {
          text: "Account",
          align: "left",
          sortable: true,
          value: "account",
        },
        {
          text: "Customer",
          align: "left",
          sortable: false,
          value: "customer",
        },
        {
          text: "Ref",
          align: "left",
          sortable: false,
          value: "reference",
        },
        {
          text: "RRR",
          align: "left",
          sortable: false,
          value: "rrr",
        },
        {
          text: "Amount",
          align: "left",
          sortable: true,
          value: "amount",
        },

        {
          text: "Gateway",
          align: "left",
          sortable: false,
          value: "gateway",
        },

        {
          text: "Channel",
          align: "left",
          sortable: false,
          value: "channel",
        },

        {
          text: "Bank",
          align: "left",
          sortable: false,
          value: "bank",
        },

        {
          text: "Date",
          align: "left",
          sortable: true,
          value: "createdAt",
        },
        {
          text: "",
          align: "left",
          value: "action",
          sortable: false,
        },
      ],
      itemsPerPage: 10,
      currentPage: 0,
      count: 0,
      sortby: "name",
      sortDesc: true,
      transactions: [],
    };
  },
  created: function () {
    // console.log("---<>", this.game);
  },
  computed: {
    ...mapGetters([
      "formatTime",
      "formatDateMed",
      "formatCurrencyDecimal",
      "formatDateLong",
      "getEndDate",
      "formatDateRelative",
      "getDayStartAndEnd",
      "getSortedDates",
    ]),
    dateRangeText() {
      switch (this.selected.id) {
        case "range":
          if (this.datesForDisplay === 1) {
            return this.datesForDisplay;
          } else {
            return `${this.datesForDisplay[1]} to ${this.datesForDisplay[0]}`;
          }

        default:
          return this.selected.display;
      }
    },
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPost", "httpDelete"]),
    ...mapActions(["showMessage"]),
    refresh() {
      console.log("refresh got");
      this.getOptions(this.options);
      this.dialog = false;
    },
    openWarn(item) {
      this.selectedItem = item;
      this.warnDlg = true;
    },
    closeDiag() {
      this.dialog = false;
    },
    filterSelected() {
      this.options.page = 1;
      this.getOptions(this.options);
    },
    showDetail(item) {
      console.log(item);
      // this.dialog = true;
      // setTimeout(() => {
      //   this.$refs.dialog.planModel = item;
      // }, 100);
    },
    addPlan() {
      this.dialog = true;
      setTimeout(() => {
        this.$refs.dialog.planModel = {};
      }, 100);
    },
    searchInput() {
      this.options.search = this.search.trim();
      this.getOptions(this.options);
    },
    itemSelected(item) {
      this.selected = item;
      if (item.id === "range") {
        this.dates = [new Date().toISOString().substr(0, 10)];
        this.datesForDisplay = this.dates;
        this.dateMenu = true;
        return;
      }
      let end = this.getEndDate(item.id); //this.stopDate;
      this.dates = [
        new Date().toISOString().substr(0, 10),
        end.toISOString().substr(0, 10),
      ];
      if (item.id === "all") {
        this.options.start = "all";
        this.options.end = "all";
      } else {
        this.options.start = new Date();
        this.options.end = end;
      }
      try {
        this.getOptions(this.options);
      } catch (error) {
        this.showMessage({ text: error.message, error: true });
      }
    },
    setRange() {
      if (!this.dates || this.dates.length < 1) {
        return;
      }
      let start, end;
      if (this.dates.length == 1) {
        let resp = this.getDayStartAndEnd(new Date(this.dates[0]));
        start = resp.start;
        end = resp.end;
      } else {
        let dates = this.getSortedDates([
          new Date(this.dates[0]),
          new Date(this.dates[1]),
        ]);
        start = dates.start;
        end = dates.end;
      }
      this.dates = [
        start.toISOString().substr(0, 10),
        end.toISOString().substr(0, 10),
      ];

      this.datesForDisplay = this.dates;
      this.options.start = start;
      this.options.end = end;
      try {
        this.getOptions(this.options);
        this.dateMenu = false;
      } catch (error) {
        this.showMessage({ text: error.message, error: true });
      }
    },
    async remove() {
      // try {
      //   this.loading = true;
      //   console.log("----rem", this.selectedItem);
      //   await this.httpDelete({
      //     withAuth: true,
      //     url: `/account/${this.selectedItem._id}`,
      //     data: {},
      //   });
      //   this.showMessage({ text: "Account Removed", error: false });
      //   this.getOptions(this.options);
      //   this.warnDlg = false;
      // } catch (error) {
      //   this.showMessage({ text: error.message, error: true });
      // }
      // this.loading = false;
    },
    getOptions: async function (options) {
      this.options = options;
      this.loading = true;
      try {
        if (this.search) {
          this.options.search = this.search.trim();
        }
        // this.options.start = this.startDate;
        // this.options.end = this.stopDate;
        if (this.options.start !== "all") {
          if (!this.options.start || !this.options.end) {
            this.options.start = new Date();
            this.options.end = this.getEndDate("month");
          }
          this.dates = [
            new Date().toISOString().substr(0, 10),
            this.options.end.toISOString().substr(0, 10),
          ];
        }
        this.itemsPerPage = options.itemsPerPage;
        this.currentPage = options.page - 1;
        this.sortby =
          options.sortBy.length === 0 ? "createdAt" : options.sortBy[0];
        this.sortDesc =
          options.sortDesc.length === 0 ? true : options.sortDesc[0];
        this.search = options.search;
        let lastId = "none";
        if (this.currentPage >= this.lastPage) {
          lastId = this.last[this.currentPage];
        } else {
          lastId = this.last[this.lastPage - 1];
        }
        let resp = await this.httpGet({
          withAuth: true,
          url: "/account/get-trnx-paginated",
          data: {
            limit: this.itemsPerPage,
            page: this.currentPage,
            sortBy: this.sortby,
            sortDesc: this.sortDesc,
            search: this.search,
            end: this.options.start,
            start: this.options.end,
            filter: this.filterType,
            last: lastId,
          },
        });
        console.log("---got", resp);

        this.lastPage = this.currentPage;
        if (resp.data.data.length) {
          this.last[this.currentPage + 1] =
            resp.data.data[resp.data.data.length - 1].id;
        }
        console.log(resp.data);
        resp.data.data.forEach((item) => {
          if (item.status) {
            // item._type = `${item.bank}/${item.channel}`;
            // item.status = item.status[0].toUpperCase() + item.status.slice(1);
            // item.game = item.game[0].toUpperCase() + item.game.slice(1);
          }
        });

        this.transactions = resp.data.data;
        this.count = resp.data.count;
        // console.log("resp", resp.data);
      } catch (error) {
        console.error(error);
        this.showMessage({
          error: true,
          text: "Could not load preview, please refresh page",
        });
      }
      this.loading = false;
    },
  },
  watch: {
    search: function (val) {
      if (!val) {
        this.options.search = this.search;
        this.getOptions(this.options);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.txt {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>